<script setup lang="ts">

</script>

<template>
  <div class="card card--no-bg app-content border-none flex-1 !pb-0 !mb-0">
    <div class="app-content-messages" v-if="!!$slots.messages">
      <slot name="messages" />
    </div>

    <slot />
  </div>
</template>

<style scoped lang="scss">
.app-content-messages {
  @apply mb-6;
}
</style>
